<template lang="pug">
  #overlay-blood-sample-with-selfie
    icon.face(data="@icon/overlay/evidence-guideline-face.svg")
    icon.tube(data="@icon/overlay/evidence-guideline-tube.svg")
</template>

<script>
export default {
  props: {},

  data() {
    return {}
  },

  computed: {},

  watch: {},

  methods: {},

  components: {},
}

/* Computed ---------------------------------------------------- */
/* Watch ------------------------------------------------------- */
/* Methods ----------------------------------------------------- */
</script>
